import React from 'react';

import Main from 'components/Main';

type TemplateProps = Record<string, unknown>;

const Template: React.FunctionComponent<TemplateProps> = () => {
  return (
    <Main>
      <div className="container">
        <h1>Abuse Page</h1>
      </div>
    </Main>
  );
};

export default React.memo(Template);
